var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        cancelText: _vm.$t("Cancel"),
        title: _vm.$t("Switch public/private"),
        "confirm-loading": _vm.loading,
        "ok-button-props": { props: { disabled: !_vm.securityMode } }
      },
      on: {
        cancel: function() {
          _vm.$emit("cancel")
        },
        ok: _vm.switchCodesSecurityMode
      }
    },
    [
      _c(
        "a-select",
        {
          staticStyle: { width: "100%" },
          model: {
            value: _vm.securityMode,
            callback: function($$v) {
              _vm.securityMode = $$v
            },
            expression: "securityMode"
          }
        },
        [
          _c("a-select-option", { attrs: { value: "true" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Public")) + " ")
          ]),
          _c("a-select-option", { attrs: { value: "false" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Private")) + " ")
          ])
        ],
        1
      ),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }