//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      securityMode: ''
    };
  },
  watch: {
    securityMode(val) {
      this.$emit('change', { checked: val === 'true' });
    }
  },
  destroyed() {
    this.securityMode = '';
  },
  methods: {
    switchCodesSecurityMode() {
      this.$emit('ok', { ispublic: this.securityMode === 'true' });
    }
  }
};
